<template>
  <div
    class="sidebar-search"
    :class="{ 'sidebar-search-show': $store.state.showSidebar }"
  >
    <SearchForm />
    <div class="hide-or-show">
      <b-button
        type="is-ghost"
        class="hide-sidebar"
        @click="$store.commit('toggleSidebar')"
      >
        <b-icon
          pack="fas"
          icon="angle-double-left"
          size="is-large"
          type="is-white"
        ></b-icon>
        <h6>Свернуть</h6>
      </b-button>
    </div>
  </div>
</template>

<script>
import SearchForm from '@/components/search/SearchForm';

export default {
  name: 'SidebarSearch',
  components: {
    SearchForm,
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.sidebar-search {
  grid-area: sidebar;
  background-color: $sidebar;
  // background: linear-gradient(to top, #283e51, #0a2342);
  background: linear-gradient(to top, #204675, #15406c);
  color: $white;
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 10px 0px;

  .hide-or-show {
    display: none;
  }

  @media screen and (max-width: 960px) {
    position: fixed;
    top: 0;
    left: -999px;
    z-index: -999;
    min-width: 260px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    transition: all 0.6s;

    &.sidebar-search-show {
      left: 0;
      opacity: 1;
      z-index: 999;
      transition: all 0.3s;
    }

    .hide-or-show {
      cursor: pointer;
      display: block;
      width: 100%;

      .hide-sidebar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
        }

        h6 {
          color: #fff;
          font-weight: bold;
        }
      }

      i {
        color: inherit;
        transition: all 0.3s;

        &:hover {
          color: #365287 !important;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}
</style>

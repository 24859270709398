<template>
  <div class="main-layout">
    <SidebarSearch />
    <main>
      <Header />
      <article class="box mb-0">
        <slot />
      </article>
      <Footer />
    </main>
  </div>
</template>

<script>
import SidebarSearch from '@/components/SidebarSearch.vue';
import Header from '@/components/app/Header.vue';
import Footer from '@/components/app/Footer.vue';

export default {
  name: 'MainLayout',
  components: {
    Header,
    SidebarSearch,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.main-layout {
  display: grid;
  //grid-template-columns: minmax(300px, 0.4fr) minmax(max-content, 1.6fr);
  grid-template-areas: 'sidebar main';
  gap: 0px 0px;
  margin: 0;
  min-height: 100vh;
  height: 100%;

  main {
    position: relative;
    background-color: #eee;
    grid-area: main;
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 20px;
    grid-template-areas:
      'header'
      'content'
      'footer';
  }

  article {
    grid-area: content;
    padding: 20px;
    margin: 0 20px;
    background-color: #fff;
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'main';
  }
}
</style>

<template>
  <form @submit.prevent="submit">
    <b-field
      label="Номер заказа"
      :type="{
        'is-danger': $v.orderId.$error,
        'is-success':
          !$v.orderId.$invalid && $v.orderId.$dirty && orderId.length,
      }"
      :message="{
        'Некорректно указан номер заказа': $v.orderId.$error,
      }"
    >
      <b-input
        placeholder="Номер заказа"
        v-model.trim="orderId"
        @blur="onTouchField('orderId')"
      ></b-input>
    </b-field>
    <b-field
      label="Телефон"
      :type="{
        'is-danger': $v.phone.$error,
        'is-success': !$v.phone.$invalid && $v.phone.$dirty,
      }"
      :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
    >
      <IMaskComponent
        placeholder="+7(900)000-00-00"
        maxlength="16"
        inputmode="numeric"
        class="input"
        :class="{
          'is-danger': $v.phone.$error,
          'is-success': !$v.phone.$invalid && $v.phone.$dirty,
        }"
        :mask="'+{7}({9}00)000-00-00'"
        v-model.trim="phone"
        @blur="onTouchField('phone')"
      />
    </b-field>
    <b-field
      label="Email"
      :type="{
        'is-danger': $v.email.$error,
        'is-success': !$v.email.$invalid && $v.email.$dirty && email.length,
      }"
      :message="{ 'Некорректно указан email': $v.email.$error }"
    >
      <b-input
        placeholder="example@mail.com"
        v-model.trim="email"
        @blur="onTouchField('email')"
      ></b-input>
    </b-field>
    <b-field
      label="Кадастровый номер"
      :type="{
        'is-danger': $v.cadNumber.$error,
        'is-success': !$v.cadNumber.$invalid && $v.cadNumber.$dirty,
      }"
      :message="{ 'Некорректно указан кадастровый номер': $v.cadNumber.$error }"
    >
      <IMaskComponent
        placeholder="Введите кадастровый номер"
        inputmode="numeric"
        class="input"
        :class="{
          'is-danger': $v.cadNumber.$error,
          'is-success': !$v.cadNumber.$invalid && $v.cadNumber.$dirty,
        }"
        :mask="'00{:}00{:}000[0000]{:}0[000000]'"
        v-model.trim="cadNumber"
        @blur="onTouchField('cadNumber')"
      />
    </b-field>

      <input type="radio" v-model="project" value="egrservice.ru"> egrservice.ru <br>
      <input type="radio" v-model="project" value="egrnzakaz.ru"> egrnzakaz.ru <br>
      <input type="radio" v-model="project" value="egrnservice.ru"> egrnservice.ru

    <div class="button-wrapper">
      <b-button
        type="is-info"
        expanded
        native-type="submit"
        :disabled="submitDisabled"
        >Поиск</b-button
      >
    </div>
  </form>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import {
  minLength,
  maxLength,
  required,
  // email,
} from 'vuelidate/lib/validators';
import { onTouchField } from '@/utils/helpers';

export default {
  name: 'FormPhysical',
  data() {
    return {
      phone: '',
      email: '',
      cadNumber: '',
      orderId: '',
      project: 'egrservice.ru',
      onTouchField,
    };
  },
  computed: {
    submitDisabled() {
      return !(
        this.phone.length ||
        this.email.length ||
        this.orderId.length ||
        this.cadNumber.length
      );
    },
  },
  methods: {
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const orderData = {
        phone: this.phone,
        email: this.email,
        orderId: this.orderId,
        cadNumber: this.cadNumber,
        project: this.project,
      };

      this.$store.commit('toggleSidebar');
      this.$store.dispatch('search/searching', orderData);
    },
  },
  validations() {
    const defaultRules = {
      phone: {
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      cadNumber: {
        minLength: minLength(11),
        maxLength: maxLength(21),
      },
      email: {
        // email,
        maxLength: maxLength(40),
      },
      orderId: {
        maxLength: maxLength(32),
      },
    };

    if (this.phone.length) {
      defaultRules.phone.required = required;
    } else if (this.email.length) {
      defaultRules.email.required = required;
    } else if (this.orderId.length) {
      defaultRules.orderId.required = required;
    } else if (this.cadNumber.length) {
      defaultRules.cadNumber.required = required;
    }

    return defaultRules;
  },
  components: { IMaskComponent },
};
</script>

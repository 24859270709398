<template>
  <div class="form-wrapper">
    <h4 class="title is-4">Поиск заказа</h4>
    <FormMain />
  </div>
</template>

<script>
import FormMain from '@/components/search/form/FormMain';

export default {
  name: 'SearchForm',
  data() {
    return {
      activeTab: 0,
    };
  },
  components: {
    FormMain,
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  h4 {
    color: $white;
    font-weight: normal;
  }

  .tabs-search {
    width: 100%;

    .tabs {
      li {
        a {
          font-size: 13px;
          color: #eee;
        }

        &.is-active {
          a {
            border-bottom-color: #337bb8;
            color: $white;
          }
        }
      }
    }

    .tab-content {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 10px;
    }
  }

  form {
    width: 100%;
    height: calc(100vh - 120px);
    overflow-y: hidden;
    padding: 0 20px 0;

    .radio {
      color: #363636;

      &.is-selected {
        color: $white;
      }

      &:hover {
        box-shadow: inset 0px 0px 20px 0px rgb(69 102 153 / 60%);
      }
    }

    &:hover {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      -moz-overflow-scrolling: touch;
      -ms-overflow-scrolling: touch;
      -o-overflow-scrolling: touch;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #337bb8;
    }

    label {
      color: $white;
      font-weight: normal;
    }

    .button-wrapper {
      margin-top: 30px;
    }
  }
}
</style>
